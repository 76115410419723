.akkoordNodig{
    display: flex;
    gap: 1rem;
    min-width: 30rem;
    &__p{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        border-radius: 3px;
        background: #fee6e6;
        color: #f30e0e;
        width: 100%;
        height: 100%;
        min-height: 4rem;
    }

    &.is-open{
        opacity: 1;
        pointer-events: all;
    }
    @media screen and (max-width: 31.25rem) {
        padding-bottom: 2rem;
    }
}
