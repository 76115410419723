.login__rightpane{
    width: 72%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 62.5rem) {
        width: 100%;
        height: 75vh;
        align-items: center;
    }


    .loginform{
        max-width: 54rem;
        padding: 2rem;
        width: 100%;
        color: black;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        .loginform__errors{
            margin: -3rem 0;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem;
            background: red;
            width: fit-content;
            border-radius: 1rem;
            .loginform__error{
                color: white;
            }
        }
        .loginform__h2{
            font-size: 3rem;
            font-weight: 500;
            color: #424c55;
        }
        .loginform__form{
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .loginform__form__inputWrapper{
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .loginform__form__inputWrapper__label{
                    font-size: 2rem;
                    color: #49535d;
                }

                .loginform__form__inputWrapper__input{
                    border-radius: 3px;
                    background: white;
                    width: 100%;
                    height: 6rem;
                    border: none;
                    font-size: 1.6rem;
                    border: 1px solid #d9dfe6;
                    flex-grow: 1;
                    padding-left: 2rem;
                }
            }
            .loginform__form__loginOptionsWrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;



                .loginOptionsWrapper__inputWrapper{
                    display:  flex;
                    gap: 1.5rem;
                    flex-direction: row;
                    align-items: center;

                    .loginOptionsWrapper__inputWrapper__label{
                        font-size: 1.6rem;
                        color: #7f8a99;
                    }
                    .loginOptionsWrapper__inputWrapper__input{
                        width: 2.5rem;
                        aspect-ratio: 1/1;
                        border: 1px solid #d9dfe6;
                    }
                }

                .loginOptionsWrapper__forgotpwd{
                    font-size: 1.6rem;
                    color: #0057ff;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            .loginform__form__loginButton{
                background: #0057ff;
                color: white;
                border: none;
                border-radius: 3px;
                width: 100%;
                height: 6rem;
                font-size: 2rem;
                cursor: pointer;
                font-family: inherit;
            }

            .loginOptionsWrapper__inputWrapper__label{
                font-size: 1.6rem;
                color: #7f8a99;
                &--staysignedin{
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    position: relative;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .loginOptionsWrapper__inputWrapper__input{
                border: 1px solid #d9dfe6;
                &--staysignedin{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
            }
                
            .loginOptionsWrapper__inputWrapper__input {

                &:checked {
                    + label {
                        span{
                            background-color: #0057ff;
                            border-color: #0057ff;
                            .loginOptionsWrapper__inputWrapper__span--icon {
                                display: block;
                            }
                        }
                    }
                }
            }
            
            .loginOptionsWrapper__inputWrapper__span{
                position: absolute;
                border-radius: 3px;
                top: 0;
                left: 0;
                height: 2.5rem;
                width: 2.5rem;
                background-color: white;
                border: 1px solid #d9dfe6;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &--icon{
                    
                    width: 100%;
                    height: min-content;
                    fill: white;
                }
            }
        }
    }


}
