.issue__btnwrapper__status__popup{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    z-index: 999;
    padding: 1rem;
    background: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 0 2rem hsl(0, 0%, 90%);
}
.issue__btnwrapper__status__popup__btn{
    padding: 0 2rem;
    background: #7f8a99;
    color: white;
    height: 3rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 5rem;
    cursor: pointer;
    border: none;

    &--unread{
        background: #7f8a99;
    }
    &--complete{
        background: #7f8a99;
    }
    &--bussy{
        background: #7f8a99;
    }
}