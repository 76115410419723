.projectDetailsTitle{
    padding: 3rem;
    font-size: 2rem;
    border-bottom: 1px solid #eceff2;
    display: flex;
    justify-content: space-between;

    .projectDetailsTitle__title{
        color: #49535d;
        font-weight: 400;
        text-transform: capitalize;
    }
    > i{
        color: #b4c0d3;
        width: 1.4rem;
        cursor: pointer;
    }
    .fa-plus{
        display: none;
    }
    &--isclosed{
        border: none;
        .fa-minus{
            display: none;
        }
        .fa-plus{
            display: block;
        }
    }
}


.projectDetails{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &--h2{
        width: 100%;
        font-size: 3rem;
        font-weight: 500;
        color: #424c55;
        padding-bottom: 2rem;
    }
    &__detail{
        background: white;
        width: 100%;
        box-shadow: 0px 5px 5px 0px rgba(0,4,21,0.03);
        border-radius: 3px;
        &__akkoordwrapper{
            grid-template-columns: auto 30rem;
            @media screen and (max-width: 62.5em) {
                display: flex!important;
                flex-direction: column;
            }
        }
        &__info{
            transition: max-height 0.25s ease-in;
            padding: 3rem;
            max-height: 110rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &__wrapper{
                display: flex;
                gap: 1rem;
            }

            &--isclosed{
                transition: max-height 0.25s ease-out;
                max-height: 0;
                overflow: hidden;
                padding: 0 3rem;
            }
            &--design{
                width: 100%;
                display: grid;
                gap: 1rem;
                grid-template-columns: auto 30rem;
                grid-template-rows: 4rem 4rem;
        
            }
            &--bouwwebsite{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .urenBalk{
                    height: 4rem;
                }
            }
        }
        &--offerte{
            padding-bottom: 0;
            height: fit-content;
        }
    }
}