.urenBalk{
    display:  flex;
    justify-content: space-between;
    align-items:  center;
    font-size: 1.6rem;
    border-radius: 3px;
    background: #f5f7f9;
    padding: 0 1rem;
    color: #7f8a99;
    height: 100%;
    min-height: 4rem;
    width: 100%;

    &__info{
        display: flex;
        gap: 1rem;
        
        &--date{
            font-size: 1.2rem;
            display: flex;
            align-items:  center;
        }
    }
}
.urenBalk__hours--red{
    color: #f30e0e;
    font-weight: 400;
}