
.selectedissue{
    z-index: 900;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    background: rgba(0,0,0,0.1);
}
.selectedissue__left{
    width: 55vw;
    height: 100vh;
    cursor: pointer;
    @media screen and (max-width: 75rem) {
        width: 35vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 0;
    }
}
.selectedissue__right{
    height: 100vh;
    width: 45vw;
    background: white;
    box-shadow: 0 0 2rem rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 75rem) {
        width: 65vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 100vw;
    }
}

.selectedissue__right__header{
    padding: 2.5rem 5rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eceff2;
    height: fit-content;
}
.selectedissue__right__header__nav{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    > div{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
}
.selectedissue__right__header__nav__close{
    color: #424c55;
    font-size: 2.2rem;
    cursor: pointer;
}

.selectedissue__right__header__nav__btn{
    color: #0057ff;
    font-size: 2.8rem;
    background: none;
    border: none;
    cursor: pointer;
}
.selectedissue__right__header__nav__status{
    height: 100%;
    padding: 0.2rem 2.2rem;
    background: #7f8a99;
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    border-radius: 5rem;
    position: relative;

    &--unread{

    }
    &--completed{

    }
}
.selectedissue__right__header__nav__status__popup{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    z-index: 999;
    padding: 1rem;
    background: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 0 2rem hsl(0, 0%, 90%);
}
.selectedissue__right__header__nav__status__popup__btn{
    padding: 0 2rem;
    background: #7f8a99;
    color: white;
    height: 3rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 5rem;
    cursor: pointer;
    border: none;

    &--unread{
        background: #7f8a99;
    }
    &--complete{
        background: #7f8a99;
    }
    &--bussy{
        background: #7f8a99;
    }
}

.selectedissue__right__header__wrapper{
    display: flex;
    justify-content: space-between;
    color: #424c55;
    align-items: center;
    padding-top: 1rem;
}
.selectedissue__right__header__wrapper__title{
    font-size: 3rem;
    font-weight: 500;
}
.selectedissue__right__header__wrapper__chevron{
    cursor: pointer;
}
.selectedissue__right__header__descriptionwrapper {
    overflow: hidden;
    max-height: 0;
    pointer-events: none;
    padding-top: 1rem;
    transition: max-height 0.2s ease-in;
    &--open{
        opacity: 1;
        max-height: 20rem;
        pointer-events: all;
    }
}
.selectedissue__right__header__descriptionwrapper__description{
    opacity: 1;
    font-size: 1.8rem;
    color: #47494d;
}

.selectedissue__right__chat{
    width: 100%;
    height: 100%;
    background: #f5f7f9;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;
}
.selectedissue__right__chat__message{
    background: white;
    max-width: 80%;
    min-width: 40%;
    position: relative;
    z-index: 9;
    box-shadow: 0 0 1.5rem rgba(0,0,0,0.1);
    border-radius: 6px;

    &--right{
        align-self: flex-end;
        border-top-right-radius: 0;
        &::before{
            content: "";
            position: absolute;
            right: -1.5rem;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1.5rem 0 1.5rem 1.5rem;
            border-color: transparent transparent transparent #ffffff;
        }
    }
    &--left{
        align-self: flex-start;
        border-top-left-radius: 0;
        &::before{
            content: "";
            position: absolute;
            left: -1.5rem;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1.5rem 1.5rem 1.5rem 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }
    &--big{
        min-width: 60%;
    }
}
.selectedissue__right__chat__message__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #d9dadb;
}
.selectedissue__right__chat__message__header__userwrapper{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.selectedissue__right__chat__message__header__userwrapper__figure{
    height: 3rem;
    width: 1.8rem;
    position: relative;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    img{
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
    }
}
.selectedissue__right__chat__message__header__userwrapper__by{
    color: #63666b;
}

.selectedissue__right__chat__message__header__date{
    font-size: 1.6rem;
    color: #69717e;
}
.selectedissue__right__chat__message__content{
    padding: 2rem;
    display: flex;
    flex-direction: column;
}
.selectedissue__right__chat__message__content__opened{
    font-size: 2rem;
    font-weight: 400;
    color: #424c55;
}
.selectedissue__right__chat__message__content__title{
    color: #424c55;
    font-size: 3rem;
    padding-bottom: 1rem;
}
.selectedissue__right__chat__message__content__text{
    font-size: 1.8rem;
    color: #69717e;
}

.selectedissue__right__chatbar{
    height: 8rem;
    padding: 1.5rem;
    display: flex;
    gap: 1.5rem;
}
.selectedissue__right__chatbar__input{
    background: #fff;
    border: 1px solid #d9dfe6;
    width: 100%;
    border-radius: 3px;
    font-size: 2rem;
    padding-left: 2rem;
    &:focus{
        outline: none;
    }
}
.selectedissue__right__chatbar__button{
    height: 100%;
    aspect-ratio: 1/1;
    background: none;
    border: none;
    color: #424c55;
    cursor: pointer;
    &--send{
        background: #0057ff;
        color: white;
        border-radius: 50%;
        border: none;
    }
}