.SignaturePopup{
    width: 100%;
    height: 100vh;
    background: rgba(66, 76, 85,40%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    top: 0;
    left: 0;
    .clickablebackground{
        z-index: 900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    &__form{
        z-index: 999;
        width: 55rem;
        background: white;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__header{
            width: 100%;
            padding: 3rem;
            padding-bottom: 3.5rem;
            border-bottom: 1px solid #d9dfe6;
            display: flex;
            justify-content: space-between;
            &--h1{
                color: #49535d;
                font-weight: 400;
                font-size: 2rem;
            }
            .signaturePopup__form__header__back{

            }
        }
        &__inputWrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding: 0 3rem;
            & > div{
                position: relative;
                width: 100%;
            }
            &--label{
                color: #7f8a99;
                padding: 3rem;
            }
            &--input{
                border-radius: 3px;
                background: white;
                width: 100%;
                height: 19rem;
                border: none;
                font-size: 1.6rem;
                border: 2px  dashed #0057ff;
                margin-bottom: 1rem;
            }
            .SignaturePopup__form__inputWrapper__buttonwrapper{
                display: flex;
                justify-content: space-between;
            }
        }
        &__buttonWrapper{
            width: min-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            &--submit{
                background: #24d11b;
                color: white;
                border: none;
                border-radius: 3px;
                width: 50rem;
                height: 6rem;
                font-size: 2rem;
                cursor: pointer;
            }
            &--back{
                background: none;
                color: #49535d;
                font-weight: 500;
                border: none;
                border-radius: 3px;
                width: 50rem;
                height: 6rem;
                font-size: 2rem;
                cursor: pointer;
            }
            &--bottomText{
                padding: 3rem 0;
                font-size: 1.2rem;
                color: #7f8a99;
            }
        }

    }

    &.is-open{
    opacity: 1;
    pointer-events: all;
}
}

.savesignature{
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 2rem;
    left: 2rem;
    gap: 0.5rem;
}
.savesignature__input{

}
.savesignature__label{
    color: #0057ff;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
}

.showsignaturepathdata{
    position: absolute;
    top: 5rem;
    left: 5rem;
}
