.homePage{
    //width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 30rem auto;
    grid-template-rows: 8rem auto;
    transition: 0.5s;

    @media screen and (max-width: 75rem) {
        grid-template-columns: 100vw;
        grid-template-rows: 8rem  auto 8rem;
        
    }
}

.header{
    background: white;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: end;

    > div{
        display: flex;
        align-items: center;
        gap: 3rem;
    }
    .notifications{
        width: 3.5rem;
        height: 3.5rem;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f2f6ff;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        img{
            height: 2.3rem;
        }
        div{
            width: 0.8rem;
            aspect-ratio: 1/1;
            background: #0057ff;
            border-radius: 50%;
            position: absolute;
            top: 0.4rem;
            right: 0.6rem;
        }
    }
    .account{
        height: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding-left: 3rem;
        border-left: 1px solid #eceff2;
        cursor: pointer;
        figure{
            height: 3.5rem;
            aspect-ratio: 1/1;
            background: #f2f6ff;
            border-radius: 50%;
            box-shadow: 30px 0 30px -30px rgba(0,4,21,0.05) inset;
        }
        img{
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
        p{
            color: #7f8a99;
            font-size: 1.6rem;
            min-width: fit-content;
        }
        i{
            color: #7f8a99;
            font-size: 1.1rem;
        }
        .account__popup{
            padding: 2rem;
            background: white;
            box-shadow: 0 0 2rem hsl(0, 0%, 80%);
            position: absolute;
            transition: 0.2s ease-in;
            top: 8rem;
            display:  flex;
            flex-direction: column;
            cursor: default;
            z-index: 999;
            opacity: 0;
            pointer-events: none;
            &--isopen{
                opacity: 1;
                pointer-events: all;
            }
            &::before {
                position: absolute;
                display: block;
                right: 50%;
                top: 0;
                background: white;
                width: 1rem;
                height: 1rem;
                transform: translate(50%, -50%) rotate(45deg);
                content: "";
                z-index: 2;
            }
            .account__popup__user{
                font-size: 2rem;
                font-weight: 400;
            }
            .account__popup__btn{
                padding: 1rem;
                width: 100%;
                color: white;
                background: #0057ff;
                border: none;
                border-radius: 3px;
                cursor: pointer;
            }
        }
    }
}

.logo {
    background: #242f4c;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem 2.5rem;

    .logo__img{
        width: 19.2rem;
        height: 100%;
        polygon{
            fill: white!important;
        }
        .st5{
            fill: white!important;
        }
    }
    h1{
        font-size: 2rem;
        color: white;
        font-weight: 400;
        transition: .2s;
        min-width: 15rem;
    }
    &--display{
        display: none!important;
        height: 8rem;
        width: 8rem;
        margin-left: -2rem;
        padding: 1.5rem;
        .logo--display__img{
            fill: white!important;
            width: 6rem;
            .st5{
                fill: white!important;
            }
            polygon{
                fill: white!important;
            }
        }
    }
    @media screen and (max-width: 75rem) {
        display: none;
        &--display{
            display: flex!important;
        }
    }
}
.LinkElement{
    text-decoration: none;
}
.nav{
    background: white;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;
    
    .notifthingy{
        background: hsl(220, 100%, 95%);
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        font-size: 1.4rem;
        text-align: center;
        color: #0057ff;
       display: flex;
       align-items: center;
       justify-content: center;
    }
}

.nav__list {
    list-style: none;
}

.nav__support{
    color: #0057ff;
    text-decoration: none;
    font-size: 1.6rem;
    width: 100%;
    display: flex;
    padding-right: 2rem;
    justify-content: end;
    &:hover{
        text-decoration: underline;
    }
}

.nav__item__wrapper {
    width: 100%;
    height: 4rem;
    padding: 0 2.5rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    border-left: transparent 3px solid;
    transition: .3s ease-in-out;
    > i{
        font-size: 1.6rem;
        color: #8a93a1;
    }
}

.nav__item{
    width: 100%;
    height: 7rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .2s ease-in-out;
    text-decoration: none;
    

    &__icon{
        fill: #8a93a1;
        border: none;
        min-width: 1.7rem;
        width: 1.7rem;
        .st0{
            fill: #8a93a1!important;
        }

        > div {
            display: flex;
        }
        ellipse{
            fill: #8a93a1!important;
        }
        path {
            fill: #8a93a1!important;
        }
    }
    &__popout{
        background:  hsl(220, 100%, 95%);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-in;
        padding: 0 2rem;
        list-style: none;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        &--open{
            padding: 0.5rem 2rem 2rem 2rem;
            max-height: 20rem;
        }
    }
    &__popout__item{
        
        height: 5rem;
        background: white;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        &__title{
            font-size: 1.6rem;
            transition: 0.3s;
            color: #8a93a1;
            &--selected{
                color: #0057ff;
            }
        }
        &__amount{
            font-size: 1.2rem;
            padding: 0.5rem;
            border-radius: 4px;
            &red{
                color: #f30e0e;
                background: #fee6e6;
            }
            &blue{
                color: #0057ff;
                background: #f2f6ff;
            }
        }
        &:hover {
            .nav__item__popout__item__title{
                color: #0057ff!important;
            }
        }
    }

    &:hover {
        background: hsl(220, 100%, 95%);
        label{
            color: #0057ff;
        }
        .nav__item__icon {
            fill: #0057ff;
            .st0{
                fill: #0057ff!important;
            }
            ellipse{
                fill: #0057ff!important;
            }
            path {
                fill: #0057ff!important;
            }
        }
        .nav__item__wrapper {
            .notifthingy{
                background: white;
            }
        }
    }

    &.selected {
        background: hsl(220, 100%, 95%);
        label{
            color: #0057ff;
        }

        .nav__item__icon {
            fill: #0057ff;
            .st0{
                fill: #0057ff!important;
            }ellipse{
                fill: #0057ff!important;
            }
            path {
                fill: #0057ff!important;
            }
        }

        .nav__item__wrapper {
            border-left: #0057ff 3px solid;
            .notifthingy{
                background: #0057ff;
                color: white;
            }
        }
    }
}

.nav__item__title {
    font-size: 1.6rem;
    color: #8a93a1;
    cursor: pointer;
    text-decoration: none;
    transition: .2s;
    max-width: 120px;
}

.section{
    box-shadow: 30px 0 30px -30px rgba(0,4,21,0.05) inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    @media screen and (max-width: 31.25rem) {
        padding: 5rem 1.5rem;
    }
}

.berichtissueswitch{
    grid-row: 2/3;
    display: none;
    align-items: center;
    justify-content: center;
    height: 10rem;
    @media screen and (max-width: 75rem) {
        display: flex;
    }
}
.berichtissueswitch__wrapper{
    height: 10rem;
    width: 60vw;
    // background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5rem;
    padding: 2rem;
    // box-shadow: 0 0 1.5rem rgba(0,4,21,0.05);
}
.berichtissueswitch__wrapper__btnwrapper{
    position: relative;
    background: #f5f7f9;
    box-shadow: inset 0 0 1.5rem rgba(0,4,21,0.1);
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    display: flex;
    align-items: center;
}
.berichtissueswitch__wrapper__btnwrapper__btn{
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    font-family: inherit;
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #414447;
}
.berichtissueswitch__wrapper__btnwrapper__selected{
    position: absolute;
    left: 5px;
    width: 50%;
    height: 85%;
    background: white;
    border-radius: 5rem;
    transition: 0.3s;
    &--right{
        right: 0;
        left: auto;
    }
}

.navfiller{
    grid-row: 4/5;
    display: none;
    @media screen and (max-width: 75rem) {
        display: block;
    }
}

.mobilenav{
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   height: 8rem;
   display: none;
   background: white;
   box-shadow: 0 0 1.5rem rgba(0,4,21,0.05);
    justify-content: center;
   @media screen and (max-width: 75rem) {
    display: flex;
   }
}
.mobilenav__ul{
    display: flex;
    list-style: none;
}
.mobilenav__ul__li{
    width: 8rem;
    height: 8rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &--selected{
        background: hsl(220, 100%, 95%);
        border-bottom: #0057ff 3px solid;
        .mobilenav__ul__li__icon{
            fill: #0057ff;
            .st0{
                fill: #0057ff!important;
            }
            ellipse{
                fill: #0057ff!important;
            }
            path {
                fill: #0057ff!important;
            }
        }
        .mobilenav__ul__li__title{
            color: #0057ff;
        }
    }
    &:hover{
        background: hsl(220, 100%, 95%);
        .mobilenav__ul__li__icon{
            fill: #0057ff;
            .st0{
                fill: #0057ff!important;
            }
            ellipse{
                fill: #0057ff!important;
            }
            path {
                fill: #0057ff!important;
            }
        }
        .mobilenav__ul__li__title{
            color: #0057ff;
        }
    }
}
.mobilenav__ul__li__icon{
    fill: #8a93a1;
    border: none;
    min-width: 2rem;
    width: 2rem;
    .st0{
        fill: #8a93a1!important;
    }
    ellipse{
        fill: #8a93a1!important;
    }
    path {
        fill: #8a93a1!important;
    }

    > div {
        display: flex;
    }
}
.mobilenav__ul__li__title{
    display: none;
    position: absolute;
    bottom: 0;
    color: #8a93a1;
    font-size: 1.4rem;
}

.menuOpen{
    grid-template-columns: 8rem auto;

    .nav__item__title {
        max-width: 0;
    }

    .nav__item__wrapper {
        gap: 0;

    }

    .nav{
        &__item{
            p{
                opacity: 0;
                width: 0;
                //position: absolute;
            }
            &__title{
                opacity: 0;
               // width: 0;
                //position: absolute;
            }
        }
    }
    .logo{
        h1{
            opacity: 0;
            pointer-events: none;
            min-width: 15rem;
        }
    }
}

@media screen and (max-width: 75rem) {
    .header{
        justify-content: space-between;
    }
    .nav{
        position: absolute;
        width: 30rem;
        top: 8rem;
        height: 100%;
        left: -30rem;
        transition: 0.5s;
        z-index: 999;
        .nav__item__title{
            min-width: fit-content
        }
    }
    .logo{
        h1{
            opacity: 0;
            width: 0;
            pointer-events: none;
        }
    }

    .menuOpen{
        grid-template-columns: 30rem auto;

        .nav{
            left: 0;
            &__item{
                &__wrapper{
                    gap: 1rem;
                }

                p{
                    opacity: 1;
                    width: 2rem;
                }
                &__title{
                    opacity: 1;
                    min-width: fit-content;
                }
            }
        }
        .logo{
            h1{
                opacity: 1;
                position: initial;
            }
        }
    }
}

.usermanagement__notification{
    color:  white;
    background: #24d11b;
    font-size: 2rem;
    width: fit-content;
    height: 5rem;
    right: 1rem;
    bottom: 1rem;
    position: fixed;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}
