.issues{
    padding: 5rem;
}
.issues__title{
    padding-bottom: 2rem;
}
.issues__header{
    border-bottom: 1px solid hsl(212, 21%, 88%);
    padding-bottom: 2rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 50rem) {
        flex-direction: column;
        gap: 1rem;
    }
}
.issues__header__filterwrapper{
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 28.125rem) {
        flex-direction: column;
    }
}

.issues__header__buttonwrapper{
    display: flex;
    gap: 2rem;
    align-items: center;
    @media screen and (max-width: 50rem) {
        flex-direction: row-reverse; 
        justify-content: start;
    }
}
.issues__header__buttonwrapper__create{
    
}
.issues__header__buttonwrapper__archive{
    font-family: "Roboto", sans-serif;
    border: none;
    background: none;
    color: #0057ff;
    cursor: pointer;
    font-size: calc(15px + (18 - 15) * ((100vw - 400px) / (1920 - 300)));
    &:hover{
        text-decoration: underline;
    }
}



.issues__issuewrapper{
    background: white;
    width: 100%;
    padding: 0 3rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
}
.issues__issue{
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-bottom: 1px solid #eceff2;
    background: white;
    width: 100%;
    &:last-child{
        border-bottom: none;
    }
    @media screen and (max-width: 37.5rem) {
        flex-direction: column;
        gap: 2rem;
    }
}

.issues__issue__textwrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.issues__issue__textwrapper__div{
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    &:hover{
        .issues__issue__textwrapper__div--title{
            text-decoration: underline;
        }
    }

    &--title {
        font-size: 1.6rem;
        color: #69717e;
        min-width: fit-content;
    }
    &--date {
        font-size: 1.2rem;
        color: #7f8a99;
    }
    
    @media screen and (max-width: 43.75rem) {
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
}
.issues__issue__btnwrapper__userwrapper{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.issues__issue__btnwrapper__userwrapper__chevron{
    padding-right: 1rem;
    font-size: 1.4rem;
    color: #424c55;
}
.issues__issue__btnwrapper__userwrapper__figure{
    height: 3rem;
    width: 1.8rem;
    position: relative;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    &--img{
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
    }
    &:hover &__by{
        opacity: 1;
        pointer-events: all;
    }
}
.issues__issue__btnwrapper__userwrapper__figure__by{
    color: #4b5563;
    transition: 0.4s;
    pointer-events: none;
    opacity: 0;
    z-index: 999;
    background: white;
    border-radius: 3px   ;
    position: absolute;
    font-size: 1.2rem;
    min-width: max-content;
    top: -3px;
    left: 2rem;
    box-shadow: 0 0 0.5rem hsl(0, 0%, 85%);
    padding: 3px;
}
.issues__issue__btnwrapper {
    display: flex;
    gap: 1.5rem;
    @media screen and (max-width: 37.5rem){
        padding-left: 1rem;
    }
}
.issues__issue__btnwrapper__status{
    height: 100%;
    padding: 0 2rem;
    background: #7f8a99;
    color: white;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 5rem;
    position: relative;
    &--unread{

    }
    &--completed{

    }
}
.issues__issue__btnwrapper__btn{
    font-size: 2.2rem;
    color: #0057ff;
    min-width: fit-content;
    border: none;
    background: none;
    cursor: pointer;
}

.issues__footer{
    padding-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
}
.issues__footer__amountshown{
    font-size: 1.2rem;
    color: #69717e;
}
.issues__footer__amountprogressbar{
    width: 20rem;
    height: 0.4rem;
    background: white;
    overflow: hidden;
    &--progress{
        height: 100%;
        background: #d9dfe6;
    }
}
.issues__footer {
    .issues__footer__loadmorebutton {
        border-radius: 3px;
        padding: 1rem 2.5rem;
        background: white;
        color: #0057ff;
        font-size: 1.2rem;
        font-weight: 400;
        transition: 0.3s;
        &:hover{
            margin-top: 0.8rem;
        }
    }
}
