.notificationsPopup{
    position: absolute;
    width: 47rem;
    height: 36.5rem;
    background: white;
    box-shadow: 0 0 2rem hsl(0, 0%, 90%);
    border-radius: 0.5rem;
    right: 4rem;
    top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 999;
    &::before {
        position: absolute;
        display: block;
        right: 48%;
        top: 0;
        background: white;
        width: 1rem;
        height: 1rem;
        transform: translate(50%, -50%) rotate(45deg);
        content: "";
        z-index: 2;
    }
}

.notificationsPopup__title{
    font-size: 2rem;
    color: #424c55;
    padding: 2rem;
    font-weight: 400;
    width: 100%;
}

.notificationWrapper{
    border-top: 1px solid #eceff2;
    padding: 2rem;
    width: 100%;
    display:  flex;
    flex-direction: column;
    gap: 2rem;
}

.notification{
    display: flex;
    width: 100%;
    gap: 2.5rem;
}
.notification__img{
    border-radius: 50%;
    width: 3.5rem;
    aspect-ratio: 1/1;
}
.notification__textwrapper{

}
.notification__textwrapper__title{
    font-size: 1.6rem;
    font-weight: 400;
    color: #49535d;
    &--bold{
        font-weight: 500;
    }
}
.notification__textwrapper__time{
    font-size: 1.2rem;
    color: #7f8a99;
}

.notificationsPopup__buttonrapper{
    height: 100%;
    display: flex;
    align-items: end;
}
.notificationsPopup__buttonwrapper__button{
    color: #0057ff;
    font-size: 1.6rem;
    text-decoration: none;
    padding-bottom: 2rem;
    display: flex;
    justify-self: end;
    background: none;
    border: none;
    cursor: pointer;
}

.is-open{
    opacity: 1;
    pointer-events: all;
}

.notificationsPopup__buttonwrapper__LinkElement{
    text-decoration: none;
}
