 .offerteondertekenen__header {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 3rem;
        padding-bottom: 3.5rem;
    }
        .offerteondertekenen__header__backbutton{
            border-radius: 3px;
            background: #0057ff;
            font-family: 'Roboto', sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
            color: white;
            height: 5rem;
            padding: 0 1.8rem;
        }
        .offerteondertekenen__header__title{
            color: #424c55;
            font-size: 3rem;
            font-weight: 500;

        }
        .offerteondertekenen__section{
            background: #fff;
            border-radius: 3px;
        }

    .offerteondertekenen__wrapper {
        width: 100%;
        display: flex;
        gap: 4rem;
        @media screen and (max-width: 87.5rem) {
            flex-direction: column-reverse;
        }
        @media screen and (max-width: 47rem) {
            flex-direction: row;
        }
        @media screen and (max-width: 37.5rem) {
            flex-direction: column-reverse;
        }
    }
        .offerteondertekenen__wrapper__section {
            background: white;
            border-radius: 3px;
        }
            .offerteondertekenen__section__header {
                padding: 3rem;
                border-bottom: 1px solid #eceff2;
                display: flex;
                justify-content: space-between;
            }
                .offerteondertekenen__section__header__title{
                    font-size: 2rem;
                    color: #49535d;
                    font-weight: 400;
                }
                .offerteondertekenen__section__header__a{
                    font-size: 1.6rem;
                    color: #0057ff;
                    text-decoration: underline;
                }

    .offerteondertekenen__pdfsection{
        max-width: 107rem;
        width: 100%;
        height: fit-content;
    }
    .offerteondertekenen__pdfsection__pdf{
        padding: 6rem;
        display: flex;
        justify-content: center;
    }
    .offerteondertekenen__pdfsection__pdf__figure{
        border-radius: 6px;
        box-shadow: 0px 2px 30px 0px rgba(0, 20, 83, 0.15);
        max-height: 53rem;
        height: 53rem;
        width: fit-content;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .offerteondertekenen__pdfsection__pdf__figure__img{
        height: 100%;
    }
    .offerteondertekenen__pdfsection__pdf__figure__download{
        width: 12rem;
        height: 12rem;
        aspect-ratio: 1/1;
        position: absolute;
        background: #0057ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .offerteondertekenen__pdfsection__pdf__figure__download__icon{
        width: 5rem;

    }
    .offerteondertekenen__sidebar {
        max-width: 41rem;
        width: 100%;
        height: fit-content;
    }

    .offerteondertekenen__sidebar__header{
        border-bottom: 1px solid #eceff2;
        display: flex;
        justify-content: space-between;
        padding: 3rem;
    }
    .offerteondertekenen__sidebar__header__title{
        color: #49535d;
        font-size: 2rem;
        font-weight: 400;
    }
    .offerteondertekenen__sidebar__header__checkmark{
        color: white;
        background: #23d11b;
        font-size: 1.6rem;
        padding: 0 0.8rem;
        height: 3.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
        .offerteondertekenen__sidebar__inputwrapper {
            padding: 3rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
            .offerteondertekenen__sidebar__inputwrapper__label{
                padding-bottom: 1rem;
                color: #49535d;
                font-size: 2rem;
                font-weight: 400;
            }
            .offerteondertekenen__sidebar__inputwrapper__input{
                height: 6rem;
                font-size: 1.6rem;
                padding-left: 1rem;
                color: #7f8a99;
                border: 1px solid #d9dfe6;
            }
            .offerteondertekenen__sidebar__inputwrapper__button{
                background: #24d11b;
                border-radius: 3px;
                font-size: 2rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                color: white;
                height: 6rem;
                width: 100%;
            }
        .offerteondertekenen__sidebar__date{
            color: #7f8a99;
            font-size: 1.2rem;
            padding-bottom: 3rem;
            width: 100%;
            text-align: center;
        }

.react-pdf__Document{
    padding: 5rem;
}

.react-pdf__Page__textContent{
    display: none;
}
.react-pdf__Page__annotations{
    display: none;
}

.react-pdf__Page{
    height: fit-content;
    position: relative;

}
.react-pdf__Page__canvas{
    width: 100%!important;
    height: 100%!important;
    box-shadow: 0 0 2rem hsl(0, 0%, 70%);

}

.offertepdf__nav{
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    left: 0;
    right: 0;
    pointer-events: none;

    &__button{
        pointer-events: all;
        border: none;
        background: #0072fe;
        width: 14rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        box-shadow: 0px 2px 10px 0px rgba(0,51,96,0.15);
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-child {
            margin-left: -9rem;
        }
        &:last-child {
            margin-right: -9rem;
        }

        i{
            color: white;
            position: absolute;
            font-size: 3rem;

            &.right{
                left: 2rem;
            }
            &.left{
                right: 2rem;
            }
        }
    }
}
